<template>
  <div class="col-12" v-if="ready">
     <b-overlay>
        <b-modal v-model="$store.state.modalRegister"
      :header-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :header-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :body-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :body-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :footer-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :footer-text-variant="($store.state.dark) ? 'light' : 'dark'"
      hide-header hide-footer no-close-on-backdrop no-close-on-esc size="lg"  centered>
        <register-content-prom  side='clinet' @cls='cls'></register-content-prom>
        <!-- <register-content v-else side='clinet' @cls='cls'></register-content> -->
      </b-modal>
      <b-modal v-model="$store.state.modalReferral"
      :header-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :header-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :body-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :body-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :footer-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :footer-text-variant="($store.state.dark) ? 'light' : 'dark'"
      hide-header hide-footer no-close-on-backdrop no-close-on-esc size="lg"  centered>
        <div class="p-2 pr-3 row m-0">
          <span class='float-right link txGold' style="position: absolute;right: 30px;z-index:888888888;" @click='cls2'> <b>x</b></span>
          <div class="col-12">
            <h5 class='txGold text-center mt-4'>Referral Link / QR Code</h5>
            <!-- Referral Link / QR Code -->

          </div>
          <div class="col-12 mb-lg-3 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">EMAIL</label> -->

              <input-comp-small fors='signup'
              :error="(emailValidated) ? false : true"
                    :errorbox="(formSubmitted) ? ((emailValidated) ? false : true) : false"
              @input='updateemail' name='email' :value='email'
               type='email' placeholder='Enter your Email'></input-comp-small>

            </div>
          </div>
          <div class="col-12 px-1 mb-lg-4" v-if="status">
            <button class="btn form-control btn-primary" :disabled="true"> 
            <img src="@/assets/images/tick.png" v-if="status == 3" alt="" width="30px" height="auto"  srcset="">
            <span class="qm" v-if="status == 1 || status == 2"> ? </span>
            </button>
          </div>
          
          <div class="col-12 mt-2 mb-lg-4" v-if="status">
            <div class="row m-0 p-0">
              
            <div class="col-12 p-0 m-0">
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 1" @click="checkREferral"> <b>CLICK HERE</b> ONCE VERIFIED </button>
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 2" @click="checkREferral"> <b>CLICK HERE</b> TO EMAIL YOUR QR CODE </button>
               <!-- <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 2" @click="openRegister"> <b>CLICK HERE</b> TO REGISTER </button> -->
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 3" @click="copy(messages[3])"> <b>CLICK HERE</b> TO COPY YOUR LINK </button>
            </div>
            </div>
          </div>
          <div class="col-12 my-lg-4" v-else>
            <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" @click="checkREferral" :disabled="!emailValidated">
              <b>CLICK HERE</b> TO RECEIVE YOUR REFERRAL LINK / QR CODE
              <!-- CLICK HERE TO RECEIVE YOUR REFERRAL LINK  -->
            </button>
          </div>
          <div class="col-12">
          <span class="text-center txGold link" v-if="status == 1 && emailValidated" @click="sendVerifEmail">
          Please click here to send email again</span>
          </div>
          <div class="col-12 px-1" v-if="status == 3">
            <img :src="qrImage" class="d-block mx-auto" alt="" srcset="" style="max-width:300px;">
            <p class="txGold link" @click="sendQrCode"> <b>CLICK HERE</b> TO EMAIL YOUR QR CODE </p>
          </div>
          <div class="col-12 px-1 m-0">
              <p class=" text-danger"
              v-if="status == 3">{{messages[status]}}</p>
              <p class="text-danger" v-else>{{messages[status]}}</p>
            </div>
            <div class="col-12 p-0 m-0">
            <button class="txGold btn btn-link d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 2" @click="openRegister"> <b>CLICK HERE</b> TO REGISTER </button>
           </div>  
        </div>
        
      </b-modal>
      <b-modal  v-model="$store.state.modalID"
      @shown="loadCaptcha"
      :header-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :header-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :body-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :body-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :footer-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :footer-text-variant="($store.state.dark) ? 'light' : 'dark'"
      hide-header hide-footer no-close-on-backdrop no-close-on-esc size="lg"  centered>
        <div class="p-2 pr-3 row m-0 py-3">
          <span class='float-right link txGold' style="position: absolute;right: 30px;z-index:888888888;" @click='cls3'> <b>x</b></span>
<div class="col-12">
  <h5 class='txGold text-center'>Get Username</h5>
</div>
          <div class="col-12 px-1">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">EMAIL</label> -->

              <input-comp-small fors='signup' :error="(emailValidated) ? false : true" @input='updateemail' name='email' :value='email'
               type='email' placeholder='Enter your Email'></input-comp-small>

            </div>
<span class="link txGold pl-1" @click="cls5">CLICK <b>HERE</b> TO RESET YOUR USERNAME</span>

          </div>
          <div class="col-12 p-0 m-0" style="">
              <div class="cap-c">
                <div class="g-recaptcha" id='captcha-two' data-callback="myCallback" :data-theme="theme" :data-sitekey="rcapt_sig_key">
                </div>
            </div>
          </div>

          <div class="col-12 px-1 mb-lg-4" v-if="status">
            <button class="btn form-control btn-primary" :disabled="true"> 
            <img src="@/assets/images/tick.png" v-if="status == 3" alt="" width="30px" height="auto"  srcset="">
            <span class="qm" v-else> ? </span>
            </button>
          </div>
          
          <div class="col-12 mt-2 mb-1" v-if="status">
            <div class="row m-0 p-0">
              
            <div class="col-12 p-0 m-0">
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 1" @click="checkREferralID"> <b>CLICK HERE</b> ONCE VERIFIED </button>
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 2" @click="checkREferralID"> <b>CLICK HERE</b> TO REVEIVE YOUR USERNAME </button>
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 3" disabled> USERNAME HAS BEEN SENT </button>
               <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" disabled v-if="status == 4" > Can Not Send Username  </button>
            </div>
            
            </div>
          </div>
          <div class="col-12 mt-2" v-else>
            <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;"  @click="checkREferralID" :disabled="!emailValidated || !capStatus"> 
              <b>CLICK HERE</b> TO RECEIVE YOUR USERNAME </button>
          </div>
          <div class="col-12">
          <span class="text-center txGold link" v-if="status == 1 && emailValidated" @click="sendVerifEmail">
            Please click here to send email again</span>
          </div>
          <div class="col-12 px-1 p-0 m-0">
              <p class="txGold" v-if="status == 3">{{messages[status]}}</p>
              <p class="text-danger" v-else>{{messages[status]}}</p>
            </div>
           <div class="col-12 p-0 m-0">
            <button class="txGold btn btn-link d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status == 2" @click="openRegister"> <b>CLICK HERE</b> TO REGISTER </button>
           </div> 

        </div>
        
      </b-modal>
      <b-modal id="modal-nick" v-model="$store.state.modalNick"
      @shown="loadCaptcha"
      :header-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :header-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :body-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :body-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :footer-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :footer-text-variant="($store.state.dark) ? 'light' : 'dark'"
      hide-header hide-footer no-close-on-backdrop no-close-on-esc size="lg"  centered>
        <div class="px-2 row m-0 py-3">
          <span class='float-right link txGold' style="position: absolute;right: 30px;z-index:888888888;" @click='cls4'> <b>x</b></span>
          <div class="col-12">
  <h5 class='txGold text-center'>Update Username</h5>
</div>
          <div class="col-12 mb-lg-3 px-1" v-if="status !== 4 && status !== 6">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">EMAIL</label> -->

              <input-comp-small fors='signup' :error="(emailValidated) ? false : true" @input='updateemail' name='email' :value='email'
               type='email' placeholder='Enter your Email'></input-comp-small>
            </div>
          </div>
          <div class="col-12 mb-lg-3 px-1" v-if="status !== 4 && status !== 6">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">Username</label> -->

              <input-comp-small fors='signup' :error="(NickName.length > 1) ? false : true" :alphNumeric="true" @input='updateNN' name='nickname' :value='NickName'
               type='text' placeholder='Enter your Username'></input-comp-small>

            </div>
          </div>
          <div class="col-12 mb-lg-3 px-1" v-if="status == 4 || status == 6">
            <div class="form-group">
              <!-- <label class='m-0' for="first_name">Verification Code</label> -->
              <input-comp-small fors='signup' @input='updateVC' name='vfc' :value='Vcode'
               type='text' placeholder='Enter Verification Code'></input-comp-small>

            </div>
          </div>
          <div class="col-12 p-0 m-0" style="">
              <div class="cap-c">
                <div class="g-recaptcha" id='captcha-two' data-callback="myCallback" :data-theme="theme" :data-sitekey="rcapt_sig_key">
                </div>
            </div>
          </div>
          
          
          <div class="col-12 p-0 m-0">
            <div class="row justify-content-center p-0 m-0">

              

            <div class="col-12 my-lg-3">
            <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;" v-if="status < 4" @click="getNickName" 
            :disabled="!emailValidated || !capStatus"> <b>CLICK HERE</b> 
              TO UPDATE YOUR USERNAME </button>
              <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;"  v-if="status == 4 || status == 6" @click="completeNN" > 
                <b v-if="status == 4">Verify</b>
                <b v-else>Retry</b>
               </button>
                <button class="btn-reg d-block mx-auto" style="padding:.8rem 1.5rem !important;"  v-if="status == 5 || status == 7" @click="cls4()" > 
                <b>Continue</b> </button>
          </div>
            </div>
          </div>  
          
          <div class="col-12">
          <span class="text-center txGold link" v-if="status == 1 && emailValidated" @click="sendVerifEmail">Please click here to send email again</span>
          </div>
          <div class="col-12 px-0">
            <span class="text-danger"> 
              Note: Username will be used in a public leaderboard, we advise not to choose your real name.
             </span>

          </div>
          <div class="col-12 px-0" v-if="status">
              <p class=" text-danger " v-if="status == 3">{{messagesNick[status]}}</p>
              <p class=" text-danger " v-else>{{messagesNick[status]}}</p>
            </div>
        </div>
        
      </b-modal>
     </b-overlay>
    </div>
</template>
<script>
  // import VueQrcode from 'vue-qrcode'
// import RegisterContent from "./RegisterContent.vue"
import RegisterContentProm from "./RegisterContentProm.vue"
import InputCompSmall from '@/components/InputCompSmall.vue'
import { Profanity, ProfanityOptions } from '@2toad/profanity';
import { BModal, BOverlay } from 'bootstrap-vue'

const options = new ProfanityOptions();
options.wholeWord = false;

const profanity = new Profanity(options);
export default {
  name: "RegisterModal",
  computed:{
    theme(){
        if (this.$store.state.dark) {
          return 'dark'
        }else{
          return 'light'
        }
      },
  },
  components:{BModal, BOverlay,
RegisterContentProm,InputCompSmall },
  data(){
    return {
      ready:true,
      rcapt_sig_key:'6LeQR0ceAAAAADXQ0t9OuPM8-l6GSEtmtU9zg_p3',
      capStatus:false,

      // loading:false,
      email:'',
      formSubmitted:false,
      emailValidated:false,
      messages:[
          "",
          "Your Address hasn't been verified, please check your mailbox.  If you can't find this email please contact support@fuse.gold",
          'Email address not recognised, please register first',
          'REGISTRATION LINK'
          ],
      messagesNick:[
          "",
          "Your Address hasn't been verified, please check your mailbox.  If you can't find this email please contact support@fuse.gold",
          'Email address not recognised, please register first',
          'Nickname already Assigned',
          'Nickname already Taken',
          'NICKNAME'
          ],
          UID:'',    
          NickName:'',
          Vcode:'',
      status:0,
      qrImage:null,
    }
  },
  watch:{
    email(email){
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.emailValidated = (!filter.test(email)) ? false : true;
      }
  },
  mounted(){
    // setTimeout(() => {
    //     let cont = document.getElementById('captcha')
    //   grecaptcha.render(
    //         cont,
    //         {"sitekey": this.rcapt_sig_key}
    //         )
    //         window.myCallback = this.imNotARobot
    //   }, 500);
      
    //   setTimeout(() => {
    //     let elm = document.getElementById('captcha').childNodes
    //     console.log(elm[0])
    //     // elm[0].style.width = '336px'
    //   }, 1000);
  },
  methods:{
    loadCaptcha(){
      console.log('loading captcha')
      setTimeout(() => {
        let cont = document.getElementById('captcha-two')
        console.log('captcha element',cont)
      grecaptcha.render(
            cont,
            {"sitekey": this.rcapt_sig_key}
            )
            window.myCallback = this.imNotARobot
      }, 500);
      
      setTimeout(() => {
        let elm = document.getElementById('captcha-two').childNodes
        console.log('captcha element',elm)

        console.log(elm[0])
        // elm[0].style.width = '336px'
      }, 1000);
    },
    imNotARobot(data) {
        this.capStatus = true
      },
    async completeNN(){
      if (this.Vcode.length == 6 ) {
      let dec = await this.$store.dispatch("updateUsernames", {nickname:this.NickName,usnCode: this.Vcode, email:this.email})
      console.log("response", dec)
      this.status = dec.status
      this.messagesNick[dec.status] = dec.message
      this.$root.Toast.fire({icon: "info",title:this.messagesNick[dec.status]})  
      } else {
      this.$root.Toast.fire({icon: "info",title:"Please Enter 6 Digists Verification Code"})
      }
    },
    async sendQrCode(){
    let dec = await this.$store.dispatch("sendQrCode", {qr:this.qrImage, email: this.email}) 
    this.$root.Toast.fire({icon: "info",title:"QrCode Has Been Sent To Your Email"})  
    
    },
    goToRef(){
      this.cls4();
      setTimeout(() => {
        // this.$bvModal.show("modal-id")
      this.$store.state.modalID = true

      }, 2000);
    },
    async getNickName(){
      let usnCode = Math.floor(100000 + Math.random() * 900000)
      if (this.NickName.length > 1) {
    let NN = profanity.exists(this.NickName);
      if (NN) {
        this.$root.Toast.fire({icon: "warning",title:"Please refrain from using inappropriate language in your Username"})
      } else {
        // return
        let dec = await this.$store.dispatch("getNickName", {nickname:this.NickName,email: this.email, usnCode})
      // console.log
      this.status = dec.status
      this.messagesNick[dec.status] = dec.message
      this.$root.Toast.fire({icon: "info",title:this.messagesNick[dec.status]})     
      }

     
      } else {
      this.$root.Toast.fire({icon: "info",title:"Username must be atleast 2 Characters"})
      }
    },

    async sendVerifEmail(){
        let dec = await this.$store.dispatch("sendVerifEmail", {email: this.email})
        if (dec.status){
      this.$root.Toast.fire({icon: "success",title:"Verification Email Sent"})
        }
    },
      copy(link){
      navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Referral Link Copied"})
    },
    copyID(link){
      navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Referral ID Copied"})
    },
    copyName(link){
      navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Nickname Copied"})
    },
    openRegister(){
      this.ready = false
      this.$store.state.modalReferral = false
      this.status = 0
        this.ready = true
      setTimeout(() => {
      this.$store.state.modalRegister = false
        // this.$bvModal.show('modal-register')
      }, 2000);
      
    },
    
      async checkREferralID(){
        this.$store.state.routeLoader = true
        let dec = await this.$store.dispatch("checkReferralID", {email: this.email})
        this.status = dec.status
        // if (dec.status == 3) {
          this.messages[dec.status] = dec.id
        // }
        this.$store.state.routeLoader = false
      },
      async checkREferral(){
        let dec = await this.$store.dispatch("checkReferral", {email: this.email})
        console.log(dec)
        if (dec.status == 3) {
          this.qrImage = dec.qrImage
          this.messages[3] = location.origin+'/referral/'+dec.id
        }
        this.status = dec.status

      },
      updateemail(value){this.email = value},
      updateUID(value){this.UID = value},
      updateNN(value){this.NickName = value},
      updateVC(value){this.Vcode = value},
      
    cls() { 
      // this.$bvModal.hide('modal-register');
      this.$store.state.modalRegister = false

      this.capStatus = false},
    cls2() { 
      // this.$bvModal.hide('modal-referral');
      this.$store.state.modalReferral = false
      
      this.status = 0; this.messages[3] = "Referral Link";this.capStatus = false},
    cls3() { 
      // this.$bvModal.hide('modal-id');
      this.$store.state.modalID = false

      this.status = 0; this.messages[3] = "Referral Link";
      this.capStatus = false
    },
    cls4() { 
      // this.$bvModal.hide('modal-nick');
      this.$store.state.modalNick = false

      this.status = 0; this.messages[3] = "Referral Link"; this.Vcode = null; this.capStatus = false},
    cls5(){
      this.capStatus = false
      this.cls3()
      setTimeout(() => {
      // this.$bvModal.show('modal-nick');
      this.$store.state.modalNick = true

        
      }, 1000);
    }
  }

}
</script>

<style scoped>

</style>