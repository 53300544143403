<template>
  <div>
<div v-if='ready' class="p-4">
          <span class='float-right link txGold' @click='cls'> <b>x</b></span>
          <div class="row px-2 px-lg-5 m-0">
          <div class="col-12">
            <h5 class='txGold text-center'>Register Interest</h5>
          </div>
          
         <div class="col-12 col-lg-6 px-1">
            <div class="form-group mb-0">
                    <input-comp-small 
                    :error="(firstName.length > 1) ? false : true"
                    :errorbox="(formSubmitted) ? ((firstName.length > 1) ? false : true) : false" fors='signup' @input='updatefirstName' name='first_name' :value='firstName' type='text' placeholder='Enter your First Name'></input-comp-small>
            </div>
          </div>
           <div class="col-12 col-lg-6 px-1">
            <div class="form-group mb-0">
              <!-- <label class='m-0' for="first_name">Last Name</label> -->
                    <input-comp-small fors='signup' 
                    :error="(lastName.length > 1) ? false : true"
                    :errorbox="(formSubmitted) ? ((lastName.length > 1) ? false : true) : false" @input='updatelastName' name='last_name' :value='lastName' type='text' placeholder='Enter your Last Name'></input-comp-small>
            </div>
          </div>
         <div class="col-12 col-lg-6 px-1">
            <div class="form-group mb-0">
              <!-- <label class='m-0' for="email">Email</label> -->
                    <input-comp-small fors='signup' 
                    :error="(emailValidated) ? false : true"
                    :errorbox="(formSubmitted) ? ((emailValidated) ? false : true) : false" @input='updateemail' name='email' :value='email' type='email' placeholder='Enter your Email'></input-comp-small>
            </div>
          </div>
          <div class="col-12 col-lg-6 px-1">
            <div class="form-group mb-0">
              <!-- <label class='m-0' for="username">Username</label> -->
                    <input-comp-small  :alphNumeric="true" 
                    :error="(username && !usernameError) ? false : true"
                    :errorbox="(formSubmitted) ? ((username && !usernameError) ? false : true) : false" fors='signup' @input='updateusername' name='text' :value='username' type='Username' placeholder='Enter your Username'></input-comp-small>
            </div>
            
          </div>
          <div class="col-12 px-1 mt-1" >
            <div class="form-group mb-0" :class="{'border border-danger' :(formSubmitted && !numberValidated)}" style="border-radius:8px;">
              <!-- <label class='m-0' for="phone">Phone Number</label> -->
              <VuePhoneNumberInput @update="validateNumber" v-model="number" :dark="$store.state.dark" />
            </div>
          </div>
          <div class="col-12 mt-2 px-1">
            <!-- <b-form-group mb-0 label="tands" v-slot="{ ariaDescribedby }"> -->
               <b-form-checkbox v-model="acceptTC"   name="tands" id="tands" :value="true" :unchecked-value="false">
                I confirm I am over 18 years old and have read the 
                <a class="txGold" href="/goldx-terms-conditions" target="_blank"><b>terms and conditions</b></a> 
              </b-form-checkbox>
            <!-- </b-form-group mb-0> -->
          </div>
         
          <div class="col-12 p-0 mx-0 mb-3" style="">
              <div class="cap-c" :class="{'border border-danger' :(formSubmitted && !capStatus)}">
                <div class="g-recaptcha" id='captcha' data-callback="myCallback" :data-theme="theme" :data-sitekey="rcapt_sig_key">
                </div>
            </div>
          </div>
          
          <div class="col-12 mb-1 p-0 mb-3">
            <button class="btn-reg d-block mx-auto" style="max-width:300px;padding:.8rem 1.5rem !important;"   @click="register"> <b>CLICK HERE</b> TO REGISTER </button>
            
          </div>
          <div class="col-12 p-0">
            <span class="text-danger"> 
              Note: All Fields Are Mandatory, Username will be used in a public leaderboard, we advise not to choose your real name.
             </span>

          </div>
          <div class="col-12 p-0 mb-4" v-if='errors.length'>
            <p class="text-danger" v-for='(error, index) in errors' :key='index'>{{error}}  </p>
            
          </div>
          
          
          <!--  -->
          <!-- <div class="col-12 col-md-7 px-3" v-if='!$store.state.isLoading'>
              <button class="btn button-gold" @click='signup'>Register</button>
            </div> -->
          
          
          
        </div>
        
        
        <div class="text-center p-4 txGold"  v-if='$store.state.isLoading'>
          <b-spinner class='txGold' label="Text Centered"></b-spinner>
          </div>
          <p class="text-danger" v-if='error'> <b> {{errorMsg}} </b> </p>
          
        
  </div>
        
  </div>
  
</template>
<script>
// import validator from 'email-validator';
import { BFormCheckbox,BSpinner } from 'bootstrap-vue'

var validator = require("email-validator");
import InputCompSmall from '@/components/InputCompSmall.vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {mapState} from 'vuex'
// import Swal from 'sweetalert2'
// import swearjar from 'swearjar'
import { Profanity, ProfanityOptions } from '@2toad/profanity';

const options = new ProfanityOptions();
options.wholeWord = false;

const profanity = new Profanity(options);
export default {
    props:{
        side:{
            type: String,
            default: "client"
        }
    },
    name: "RegisterContent",
    components:{
      BFormCheckbox,
      BSpinner,
      VuePhoneNumberInput,
    InputCompSmall,
},
    computed:{
      ...mapState(['regType']),
      theme(){
        if (this.$store.state.dark) {
          return 'dark'
        }else{
          return 'light'
        }
      },
        notValidated(){

          return (this.firstName && this.lastName && this.emailValidated && this.numberValidated && this.capStatus && this.acceptTC)  ? false : true
        }
    },
    data() {
        return {
          formSubmitted:false,
          refAvail:false,
          acceptTC:false,
          walletAddress:'',
          refAddress:'',
          tfaError:null,
          IdDoc:null,
          SlfDoc: null,
           IdDocB64:null,
          SlfDocB64: null,
          imgQr:null,
      twoFaCode:null,    
      twoFaUser : null,
      twoFa : false,
      email:'',
      address:'',
      city:'',
      dob:null,
      country:"",
      postCode:'',
      errors:[],
      firstName:'',
      lastName:'',
      error:false,
      errorMsg:null,
      codeSent:false,
      password:'',
      confirmPassword:'',
      passwordVerifyCode:'',newPassword:'',verifyNewPassword:'',
      capStatus:false,
      rcapt_sig_key:'6LeQR0ceAAAAADXQ0t9OuPM8-l6GSEtmtU9zg_p3',
      residence:'',
      dType:1,
      mailSent:false,
      tempUser:null,
      ready:false,
      live_google_recpachid:null,
      emailValidated:false,
      numberValidated:false,
      number:'',
      username:'',
      usernameError:false
        }
    },
    watch:{
      email(email){
        // validator
        // var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        // this.emailValidated = (!filter.test(email)) ? false : true;
        this.emailValidated = validator.validate(email);
      },
      username(value){
        this.usernameError = false
        let dec = profanity.exists(this.username);

if (dec || value.length < 2) {
  // this.errors.push('Please refrain from using inappropriate language in your Username')
  this.usernameError = true
}  
      }
    },
    async mounted() {
      this.formSubmitted = false
    this.ready = true
    
    
            if (this.$route.params.ref) {
        this.refAddress = this.$route.params.ref
        this.refAvail = true
        console.log("params found",this.$route.params)
        // this.$bvModal.show('modal-register')
        this.$store.state.modalRegister = true
      }else{
        console.log("no params found",this.$route.params)
      }
      setTimeout(() => {
        let cont = document.getElementById('captcha')
      grecaptcha.render(
            cont,
            {"sitekey": this.rcapt_sig_key}
            )
            window.myCallback = this.imNotARobot
      }, 1500);
      
      setTimeout(() => {
        let elm = document.getElementById('rc-anchor-container')
        // console.log("rc-anchor-container", elm)
        // elm.style.borderRadius = '11px !important;'
      }, 1000);
    },
    methods:{
      validateNumber(value){
        this.numberValidated = value.isValid
        console.log("number value", value)
      },
          copy(link){
      navigator.clipboard.writeText(link)
      this.$root.Toast.fire({icon: "success",title:"Link Copied"})
    },
      async connect(){
        let accounts =  await ethereum.request({ method: 'eth_requestAccounts' });
        this.walletAddress = accounts[0]
      },
      
      async sendMail(user){
        let dec = await this.$store.dispatch("sendMail", {email:user, id:user._id})
        if(dec.status){
          this.mailSent = true
        }
      },
      cls() {
        // alert('asd')
        this.$emit("cls")
      },
      async updatePasswordChange() {
        if(this.newPassword.length > 5 && this.newPassword === this.verifyNewPassword && this.passwordVerifyCode.length ==6){
          this.$store.isLoading = true
          let dec = await this.$store.dispatch('updatePassword',
           {username:this.username, password: this.newPassword, code:this.passwordVerifyCode}
           )
           console.log(dec)
            this.$store.isLoading = false;
            if(dec.status)this.codeSent = true
            this.$root.Toast.fire({
            icon: dec.icon,
            title: dec.message
          })
          this.codeSent = false
          this.newPassword = '';
          this.verifyNewPassword = '';
          this.passwordVerifyCode = ''
          this.username = '';
          this.$store.action = "signin"
        }else{
          let message = '';
          if(this.newPassword.length < 6) message = 'Password Length must be at least 6 characters'
          if(this.newPassword.length !== this.verifyNewPassword) message = 'Password Must match with Verify Password'
          if(this.passwordVerifyCode.length !== 6) message = 'Password Recovery Code must b 6 characters'
           this.$root.Toast.fire({
            icon: "warning",
            title: message
          })
        }
      },
      async forgot(){
        if(this.username.length){
          this.$store.isLoading = true
          let dec = await this.$store.dispatch('sendPasswordResetCode', this.username)
            this.$store.isLoading = false;
            if(dec.status)this.codeSent = true
            this.$root.Toast.fire({
            icon: dec.icon,
            title: dec.message
          })
        }
      },
      imNotARobot(data) {
        this.capStatus = true
      },
      

      updatelastName(value){this.lastName = value},
      updatefirstName(value){this.firstName = value},
      updateemail(value){this.email = value},
      updateusername(value){this.username = value},
      async register() {
        // this.$bvModal.hide('modal-register')
       
        // this.$root.Swal.fire({
        //     icon: 'error',
        //     title: "Currently under maintenance and services will be resumed as soon as possible.  Apologies for any inconvenience, please contact support@fuse.gold with any immediate queries."
        //   })
        // return;
        this.usernameError = false

        this.formSubmitted = true
        if (this.notValidated) {
          console.log("Not Validated = true")
        } else {
          this.errors = [];
          console.log("not validated = false")
        if (this.firstName.length < 2) {
          this.errors.push('First Name Must be at least 2 characters long')
        }
        if (this.lastName.length < 2) {
          this.errors.push('Last Name Must be at least 2 characters long')
        }
        if (this.username.length < 2) {
          this.errors.push('Username Must be at least 2 characters long')
        }
        // let dec = swearjar.profane(this.username);
    let dec = profanity.exists(this.username);

        if (dec) {
          this.errors.push('Please refrain from using inappropriate language in your Username')
          this.usernameError = true

        }
        let lname = this.lastName.toLowerCase();
        let fname = this.firstName.toLowerCase();
        let uname = this.username.toLowerCase();
        if (uname.includes(lname) || uname.includes(fname) ) {
          this.errors.push('Username Can Not Contain Your Firstname OR Lastname')
          this.usernameError = true

        }
        this.$store.state.isLoading = true;
        // console.log("all validation passed")
        // return
let user = {
            email:this.email,
            firstName:this.firstName,
            lastName:this.lastName,
            username:this.username,
            number:this.number,
            date:new Date(),
            refAddress:this.refAddress,
            refLink:location.origin+'/referral/'+this.walletAddress
          }
          if (!this.errors.length) {
            console.log("got no errors ")
          let dec = await this.$store.dispatch('addRegInterest',{user});
          console.log('response in registration content', dec)
          if(dec.status){
            // this.imgQr = dec.data.TwoFa.qr
            this.$store.state.isLoading = false;
            this.errors = []
            // this.$bvModal.hide('modal-register')
            this.$store.state.modalRegister = false;
        //     this.$root.Toast.fire({
        // position:'bottom-end',
        //     icon: 'success',
        //     title: "Registration successful, please check email inbox for email verification Link"
        //   })
        this.$router.push({name:"thankyou"})
          }else{
            this.errors.push(dec.message);
           this.$store.state.isLoading = false;
          }  
          }else{

            console.log("got errors ", this.errors)
        this.$store.state.isLoading = false;

          }
        }
          
      },
      async signin() {
        this.error = false;
        this.errorMsg = null;
        if(this.username.length > 4 && this.password.length > 4)
        {
        this.$store.state.isLoading = true
          let dec = await this.$store.dispatch('login', {username: this.username, password: this.password})
          console.log(dec, this.$store.state.userType)
          if(dec.status == 1){

            location.replace('/')           
            // location.reload()           
            // if(this.$store.state.userType == 2){ this.$router.push({name:'MarchantDashboard'})}
            // this.$bvModal.hide("modal-register");
            this.$store.state.modalRegister = false

          }
          else if (dec.status == 2)
          {
            // this.$bvModal.hide("modal-register");
            this.$store.state.modalRegister = false
            this.$store.state.isLoading = false
            this.tempUser = dec.user
            const swal = this.$root.Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-gold',
              denyButton: 'btn btn-link txGold'
            },
            buttonsStyling: false
          })
            swal.fire({
              toast:false,
              timer:150000,
              position:'center',
            html: `
            <h4 class='txDark py-3'>
            Your Email Address has not been verified.<br><br>
            Please check your Inbox (also check Promotions / Spam) for confirmation email.<br><br>
            Please click "Resend Email" to send the confirmation email again if required.<br><br>
            OR Click "Support" to contact support if necessary<h4>
            `,
            showDenyButton: true,
            showCloseButton: true,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'Resend Email',
            denyButtonText: `Support`,
            CancelButtonText: `Close`,
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              await this.sendMail(this.tempUser)
              this.$root.Toast.fire('Email Verification Link Sent', '', 'success')
            } else if (result.isDenied) {
              window.location.href='mailto:support@fuse.gold'
              // this.$root.Toast.fire('Contacting to Support', '', 'info')
            }
          })

          }
          else if (dec.status == 3)
          {
            //close modal
            //load twoFA popup
             this.$store.state.isLoading = false
            this.twoFaUser = dec.user
            // this.$bvModal.hide("modal-register")
            this.$store.state.modalRegister = false
          this.$root.Swal.fire({
          title: 'Submit your Authenticator Code', input: 'number',
          inputAttributes: { autocapitalize: 'off'},
          showCancelButton: true, confirmButtonText: 'Submit',
          showDenyButton: true, denyButtonText: 'Lost Authenticator App? Contact Support',
          showLoaderOnConfirm: true,
          preConfirm: async (code) => {
            return await this.$store.dispatch('verifyTfaLogin', {code,user:this.twoFaUser, login: true})

              // return await this.$store.dispatch('verifyTfa', {mode,code, tfaStatus:(newValue) ? "Running" : "Active"})
          },
          allowOutsideClick: () => !this.$root.Swal.isLoading()
          })
          .then(async (res) => {
            console.log("verifyTfa",res)
            if(res.isConfirmed)
            {
              if (res.value.status == 1) 
              {
                location.replace('/')           
              // Swal.fire({icon:"success",title: `2FA Status Updated`})
              }
              else
              {
                if(res.value.error !== undefined)
                {
                  if(res.value.error == -1) this.tfaError = "Key Was Entered Too Late"
                  if(res.value.error == 1) this.tfaError = "Key Was Entered Too Early"
                  if(res.value.error == null) this.tfaError = "Invalid Code Provided, please try again"
                }else{
                  this.tfaError = "Failed to authenticate the key"
                }
                  this.$root.Toast.fire({ icon: 'error', title: this.tfaError})
                // this.$store.dispatch('update2faStatus', !newValue)          
              this.updateTfaValue()
              }
            }else{
              if (res.isDenied) {
                await this.$store.dispatch("requestTfaReset", this.twoFaUser._id);
              this.$root.Swal.fire({icon:"success",
              title: `2FA Request Sent <br <br> 
              An update will be provided via your registered email address for Fuse.Gold`})

              }
              this.updateTfaValue()
            }
              // this.updateTfaValue()
          })
           
          }
          else{
            this.$store.state.isLoading = false
            this.error = true;
            this.errorMsg = dec.message;
          }
        }else{
          this.errorMsg ='Please enter valid username and password'
          this.error = true;
        }
      }
    }
}
</script>

<style scoped>
#captcha > div {
  width: 500px !important;
}
</style>