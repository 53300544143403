<template>
    <div class='ip-container my-2'
    :class='{"clDark": this.$store.state.dark, "border border-danger": errorbox}'
    >
    <div v-if='fors == "signup"'>
        <form action="" autocomplete="off" @submit.prevent="loader">
          <span class="plcholder" :class="{'Valid':!error, 'Invalid':error}" v-if="value.length">{{placeholder}}</span>
        <input :disabled='disabled ? disabled : false' 
        @input='update' 
        :placeholder='placeholder' 
         autocomplete="off" 
         :value='value' :name='name'
          :id='id' :type='type'
           class='ip-comp px-2' 
           @blur="validate"
           :readonly='false'
        :style='{color:(this.$store.state.dark) ? "#FFFF" : "inherit"}'  
        :class='{"clDark": this.$store.state.dark}' >
    </form>
    </div>
    <div v-else>
  <!-- <form action="" autocomplete="off" > -->
      <span class="plcholder" :class="{'Valid':!error, 'Invalid':error}" v-if="value.length">{{placeholder}}</span>
        <input :disabled='disabled ? disabled : false' 
        @input='update' 
        :placeholder='placeholder' 
         autocomplete="off" 
         :value='value' :name='name'
          :id='id' :type='type'
           class='ip-comp px-2' 
           :readonly='false'
        :style='{color:(this.$store.state.dark) ? "#FFFF" : "inherit"}'  
        :class='{"clDark": this.$store.state.dark}' 
        v-on:keyup.enter="onEnter"
        >
    <!-- </form> -->
    </div>
    
        
    </div>
  </template>
  
  <script>
  export default {
      name: "InputComp",
      props:{
          fors:{
              type:String,
              default:"signin"
          },
          auto:{
              type:String,
              default:"off"
          },
          fill:{
              type:String,
              default:"chrome-off"
          },
          type:{type:String},
          placeholder:{type:String},
          value:{type:String},
          name:{type:String},
          disabled:{type:Boolean},
          error:{type:Boolean, default:false},
          errorbox:{type:Boolean, default:false},
          alphNumeric:{type:Boolean, default:false}
      },
      // props:['type','placeholder','value','disabled','name'],
      methods:{
          validate(ev){
              if (this.alphNumeric) {
                  var newString = ev.target.value.replace(/[^A-Z0-9]/ig, "");
              // console.log("validate", newString)  
              this.$emit('input', newString)
  
              } 
              
          },
          onEnter(){
              if(this.name == "password" && this.fors == "signin"){
                  console.log('toggleing login')
                  this.$emit("login")
              }
          },
          loader(){
              
          },
          update(value)
          {
              this.$emit('input', value.target.value)
          }
      },
      computed:{
          id(){
              return (Math.random()*1000).toFixed(0)
          }
      },
      mounted(){
          this.value = ''
      }
  }
  </script>
  
  <style scoped>
  .ip-container{
      background: rgb(234, 234, 234);
      border-radius: 7px;
      box-shadow: inset 0px 0px 54px 0px rgb(0 0 0 / 11%);
      padding: 4px 10px 7px 3px;
  }
  
  .ip-comp{
      
      width:100%;border:none;
      height: 43px;
      /* color: black; */
      background: transparent !important;
      /* border-left: 2px solid #b99653; */
   
  }
     input.ip-comp:focus {
      outline-width: 0;
  }
  .plcholder{
      position: absolute;
      font-size: 63%;
      left: 14px;
  }
  .Invalid{color:dodgerblue;}
  .Valid{
      color:yellowgreen;
  }
  </style>